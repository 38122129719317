import { Button, CircularProgress } from '@mui/material'
import React from 'react'
import { FormattedMessage } from 'react-intl'

const CommonButton = ({ title, type, onClick, loading, ...rest }) => {
  return (
    <Button
      variant="contained"
      sx={{
        padding: '10px',
        background: '#1146FF',
        color: '#fff',
        textTransform: 'capitalize',
        borderRadius: '12px',
        fontSize: '18px',
        fontWeight: '700',
        fontFamily: 'var(--font_i)',
        boxShadow: '0 2px 20px 0 rgb(20 72 255 / 39%)'
      }}
      fullWidth
      type={type}
      onClick={onClick}
      disabled={loading}
      {...rest}
    >
      {loading ? <CircularProgress size={24} color="info" /> : <FormattedMessage id={title} />}
    </Button>
  )
}

export default CommonButton
