/* eslint-disable no-useless-escape */

export const EMAIL =
  /^\s*(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/

export const CHAT_TITLE_ACTION_TYPES = {
  EDIT: 'EDIT',
  DELETE: 'DELETE',
  EDIT_SUBMIT: 'EDIT_SUBMIT',
  DELETE_SUBMIT: 'DELETE_SUBMIT'
}
