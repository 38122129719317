export const allRoutes = {
  home: '/',
  auth: '/auth',
  about: '/about',
  contact: '/contact',
  faq: '/faq',
  explore: '/explore',
  chat: '/chat',
  chatByCategory: (categoryId) => `/chat/${categoryId}`,
  profile: '/profile',
  editProfile: (type) => `/profile/${type}`,
  privacyPolicy: '/privacy-policy',
  singleChat: '/details',
  terms: '/terms',

  //  THIS IS FOR FREE MONTH

  // freeMount: '/freeMount',
  freeMountHome: '/freeMountHome',
  freeMountAbout: '/freeMountHome/About',
  freeMountCountact: '/freeMountHome/Contact',
  // this router for main form
  freeMountAuth: '/freeMountHome/auth'
}
