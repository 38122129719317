import React from 'react'
import GlobalStyles from '@mui/material/GlobalStyles'
import { useTheme } from '@emotion/react'
import { iconDownArrow } from 'assets/images'
export const GlobalTheme = ({ theme }) => {
  const { palette } = useTheme()

  return (
    <GlobalStyles
      styles={{
        html: {
          '-webkit-font-smoothing': 'antialiased',
          '-moz-osx-font-smoothing': 'grayscale'
        },
        '::selection': {
          backgroundColor: '#1549FF',
          color: '#fff'
        },
        '::-webkit-scrollbar': { width: '12px' },
        'html,body': { overflowX: 'hidden' },
        '*, *::before, *::after': {
          margin: 0,
          padding: 0,
          boxSizing: 'border-box'
        },
        'h1,h2,h3,h4,h5,h6,div,span,p': { padding: 0, margin: 0 },
        a: {
          textDecoration: 'none',
          outline: 'none',
          '&:focus,&:hover, &:active, &:visited, &:hover': {
            textDecoration: 'none',
            outline: 'none'
          }
        },
        '& .MuiCheckbox-root.Mui-checked': {
          '& svg': {
            color: palette.primary[500]
          }
        },
        button: { border: 'none', outline: 'none' },
        ul: { padding: 0, listStyle: 'none' },
        '& .phone-input': {
          "&[dir|='rtl']": {
            '& .form-control': {
              padding: '15px 80px 15px 14px !important'
            },
            '& .react-tel-input ': {
              '& .flag-dropdown ': {
                borderLeft: '1px solid rgba(52, 52, 52, 0.6)',
                borderRight: '0 !important',
                paddingLeft: '0 !important',
                '& .flag.ae': {
                  '& .arrow': {
                    left: '-30px !important'
                  }
                }
              }
            }
          },
          '& .MuiFormControl-root': {
            width: '100%',
            marginBottom: '20px',
            '& .selected-flag:before': {
              display: 'none'
            },
            '& .react-tel-input ': {
              '& .flag-dropdown ': {
                borderRight: '1px solid rgba(52, 52, 52, 0.6)',
                paddingRight: '12px',
                borderRadius: '0',
                '& .flag.ae': {
                  display: 'flex',
                  alignItems: 'center',
                  '& .arrow': {
                    backgroundImage: `url(${iconDownArrow})`,
                    backgroundSize: '100% 100%',
                    backgroundPosition: 'cover',
                    backgroundRepeat: 'no-repeat',
                    margin: '0',
                    border: '0',
                    width: '15px',
                    height: '15px',
                    left: '30px',
                    transform: 'translateY(-50%)'
                  }
                }
              },
              '& .form-control': {
                width: '100%',
                backgroundColor: '#F5F5F5',
                borderRadius: '10px',
                border: 'none',
                padding: '15px 14px 15px 80px',
                '&:focus': {
                  outline: 'none',
                  boxShadow: 'none',
                  borderColor: theme.palette.secondary[500]
                }
              }
            }
          }
        },
        '& .date': {
          border: 'none',
          borderBottom: '1px solid #cccccc',
          borderRadius: '0px !important',
          backgroundColor: 'transparent !important',
          width: '100%',
          textTransform: 'capitalize',
          fontWeight: '600 !important',
          fontSize: '16px !important',
          fontFamily: 'var(--font-p_r)',
          color: palette.secondary[700],
          padding: '7px 0 !important',
          cursor: 'pointer'
        },
        // react date picker
        '& .react-datepicker-wrapper input:disabled': {
          color: '#00000061 !important'
        },
        '& .Mui-disabled': {
          color: '#00000061 !important'
        },
        '& .form-group': {
          width: '100%',
          marginBottom: '10px !important',
          '& input': {
            padding: '14px 14px',
            color: '#263241',
            fontSize: '14px',
            fontFamily: 'var(--font_i)',
            fontWeight: 400,
            borderRadius: '12px',
            background: '#F3F3F3',
            '&::placeholder': {
              color: '#263241',
              opacity: 1
            }
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none'
          }
        },
        '& .MuiMenu-list': {
          padding: '0 !important',
          '& .Mui-selected': {
            color: '#fff',
            backgroundColor: '#0B41FF !important'
          }
        },
        '& .MuiFormHelperText-root.Mui-error': {
          fontWeight: '500',
          fontSize: '13px',
          margin: '5px 0 0 0',
          color: 'red',
          textTransform: 'capitalize'
        }
      }}
    />
  )
}
