export const apiPaths = {
  auth: '/auth',
  verifyOtp: '/verify-otp',
  resendOtp: '/resend-otp',
  profileUpdate: (id) => `/profile/update/${id}`,
  user: '/user',
  categories: '/categories',
  message: '/message',
  audioMessage: '/speechToText',
  getMessages: '/get-messages',
  getMessage: (id) => `/message/${id}`,
  updateMessage: (id) => `/message/${id}`,
  likeMessage: (id) => `/message/like/${id}`,
  clearChat: (id) => `/message/clear/${id}`,
  contact: '/contact',
  requestSubscribe: '/request-subscribe',
  requestSubscribeVerify: '/verify-subscribe',
  requestUnSubscribe: '/request-unsubscribe',
  requestUnSubscribeVerify: '/verify-unsubscribe'
}
