import React, { Fragment, useContext, useState } from 'react'
import { AppBar, Box, IconButton, Modal, Toolbar, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import MenuIcon from '@mui/icons-material/Menu'
import { Link } from 'react-router-dom'

import { LanguageContext } from 'context/languageContext'
import Language from '../language'
import { RoundedButton } from 'components'
import { allRoutes } from 'shared/constants/allRoutes'

const InnerHeader = ({
  showHome,
  removeBG,
  showIcon,
  handleSide,
  id,
  showLanguage,
  refetch,
  isFetching,
  onClearChat: clearChatHandler,
  showProfile
}) => {
  const { locale } = useContext(LanguageContext)

  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const onClearChat = () => {
    refetch()
    handleClose()
    clearChatHandler()
  }
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4
  }
  const flexCenter = {
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    gap: '20px'
  }

  return (
    <Fragment>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: removeBG ? 'transparent' : '#fff',
          boxShadow: 'none',
          padding: { xs: '10px', md: '20px 25px' }
        }}
        dir={locale === 'ar' ? 'rtl' : 'ltr'}
      >
        <Toolbar disableGutters sx={{ justifyContent: 'space-between', width: '100%' }}>
          <Box
            sx={
              {
                // flexGrow: 1
              }
            }
          >
            {showIcon && (
              <IconButton
                sx={{
                  background: '#1146FF',
                  color: '#fff',
                  height: '45px',
                  width: '45px',
                  display: { xs: 'block', md: 'none' },
                  '&:hover': { background: '#1146FF' }
                }}
                size="large"
                onClick={handleSide}
              >
                <MenuIcon />
              </IconButton>
            )}
          </Box>
          <Box
            sx={{
              justifyContent: 'flex-end',
              display: 'flex',
              alignItems: 'center',
              gap: { xs: '15px', sm: '30px' },
              '& a': {
                color: '#343434',
                textTransform: 'capitalize',
                fontWeight: '500',
                fontSize: { xs: '16px', sm: '20px' },
                padding: '0'
              }
            }}
          >
            {showHome && (
              <Link to="/">
                <FormattedMessage id="home" />
              </Link>
            )}

            {showProfile && (
              <Link to={allRoutes.profile}>
                <FormattedMessage id="profile" />
              </Link>
            )}
            {showLanguage ? (
              <Language />
            ) : (
              <>
                <Link to="/">
                  <FormattedMessage id="home" />
                </Link>
                <Language />
                <RoundedButton title="clearChat" onClick={handleOpen} loading={isFetching} />
              </>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Typography variant="h4" marginBottom="10px" textAlign="center" fontWeight="500">
            <FormattedMessage id="modalConfirmation" />
          </Typography>
          <Box sx={flexCenter}>
            <RoundedButton title="yes" onClick={onClearChat} small />
            <RoundedButton title="no" onClick={handleClose} small />
          </Box>
        </Box>
      </Modal>
    </Fragment>
  )
}

export default InnerHeader
