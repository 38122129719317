import React from 'react'

const IconTranslate = ({ color }) => {
  return (
    <svg width="44" height="45" viewBox="0 0 44 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2_54)">
        <path
          d="M39.7847 37.2251L30.2024 18.0605L20.6201 37.2251"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M23.3579 31.7495H37.0469" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M15.1445 5.74048V9.84717" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M4.19336 9.84717H26.0957" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M20.6201 9.84717C20.6201 14.2038 18.8894 18.382 15.8088 21.4626C12.7282 24.5433 8.55001 26.2739 4.19336 26.2739M10.6047 15.3229C11.739 18.5244 13.8374 21.2957 16.6113 23.2558C19.3852 25.2158 22.6982 26.2682 26.0947 26.2682"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2_54">
          <rect width="43.8047" height="43.8047" fill={color} transform="translate(0.088623 0.264893)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default IconTranslate
