import React from 'react'

const IconResearch = ({ color }) => {
  return (
    <svg width="71" height="71" viewBox="0 0 71 71" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M42.4468 18.8385L37.1968 32.6198L33.5874 29.0104C33.3687 28.7916 33.0406 28.6823 32.7124 28.6823C32.3843 28.6823 32.1656 28.7916 31.9468 29.1198L28.1187 33.7135L25.1656 29.2291C24.9468 28.901 24.6187 28.6823 24.1812 28.7916C23.8531 28.7916 23.4156 29.0104 23.3062 29.3385L17.5093 38.8541C17.1812 39.401 17.3999 40.0573 17.8374 40.3854C18.3843 40.7135 19.0406 40.4948 19.3687 40.0573L24.2906 31.9635L27.1343 36.3385C27.3531 36.6666 27.6812 36.776 28.0093 36.776C28.3374 36.776 28.6656 36.6666 28.8843 36.3385L32.9312 31.526L36.8687 35.4635C37.3062 35.901 37.9624 35.901 38.3999 35.4635C38.5093 35.3541 38.6187 35.2448 38.6187 35.026L44.4156 19.6041C44.6343 19.0573 44.4156 18.401 43.7593 18.1823C43.3218 17.9635 42.6656 18.2916 42.4468 18.8385Z"
        fill={color}
      />
      <path
        d="M7.88437 51.651H25.4937V57.0104H19.6969C19.0406 57.0104 18.6031 57.4479 18.6031 58.1041C18.6031 58.7604 19.0406 59.1979 19.6969 59.1979H42.3375C42.9937 59.1979 43.4312 58.7604 43.4312 58.1041C43.4312 57.4479 42.9937 57.0104 42.3375 57.0104H36.5406V51.651H44.1969C44.8531 51.651 45.2906 51.2135 45.2906 50.5573C45.2906 49.901 44.8531 49.4635 44.1969 49.4635H7.88437C6.35312 49.4635 5.14999 48.2604 5.14999 46.7291V16.7604C5.14999 15.2291 6.35312 14.026 7.88437 14.026H54.15C55.6812 14.026 56.8844 15.2291 56.8844 16.7604V34.0416C56.8844 34.6979 57.3219 35.1354 57.9781 35.1354C58.6344 35.1354 59.0719 34.6979 59.0719 34.0416V16.7604C59.0719 14.026 56.8844 11.8385 54.15 11.8385H7.88437C5.14999 11.8385 2.96249 14.026 2.96249 16.7604V46.6198C2.85312 49.3541 5.14999 51.5416 7.88437 51.651ZM34.2437 56.901H27.6812V51.5416H34.2437V56.901Z"
        fill={color}
      />
      <path
        d="M63.7749 46.5104C63.7749 42.026 60.1655 38.3073 55.6812 38.3073C51.1968 38.3073 47.478 41.9166 47.478 46.401C47.478 50.8854 51.0874 54.6041 55.5718 54.6041C57.2124 54.6041 58.7437 54.1666 60.0562 53.2916L65.1968 59.6354C65.5249 60.0729 66.2905 60.1823 66.728 59.8541C67.1655 59.526 67.2749 58.7604 66.9468 58.3229V58.2135L61.8062 51.7604C63.0093 50.3385 63.7749 48.4791 63.7749 46.5104ZM49.6655 46.5104C49.6655 43.2291 52.2905 40.6041 55.5718 40.6041C58.853 40.6041 61.478 43.2291 61.478 46.5104C61.478 49.7916 58.853 52.4166 55.5718 52.4166C52.3999 52.4166 49.6655 49.7916 49.6655 46.5104Z"
        fill={color}
      />
    </svg>
  )
}

export default IconResearch
