import React, { useEffect, useState } from 'react'
import { Avatar, Box, ButtonGroup, IconButton, Typography } from '@mui/material'
import { useMutation } from '@tanstack/react-query'

import CodeEditor from '@uiw/react-textarea-code-editor'
import { iconCopy, iconThumbsUp, iconCheck, iconThumbsUpFilled, iconLogoArabic } from 'assets/images'
import { copyToClipboard } from 'shared/utils'
import axiosInstance from 'shared/libs/axios'
import { apiPaths } from 'shared/constants/apiPaths'

const ChatAnswer = ({ categoryId, title, liked: userLiked, id, handleOptimisticLike }) => {
  const [copy, setCopy] = useState(false)
  const [liked, setLiked] = useState(userLiked)
  const [code, setCode] = useState()

  useEffect(() => {
    setCode(title)
  }, [title])

  useEffect(() => {
    if (copy) {
      setTimeout(() => {
        setCopy(false)
      }, 2000)
    }
  }, [copy])

  const likeMessage = async (id) => {
    await axiosInstance.put(apiPaths.likeMessage(id))
  }

  const likeMutation = useMutation((data) => likeMessage(data))

  const handleCopy = (text) => {
    copyToClipboard(text)
    setCopy(!copy)
  }
  const onLike = () => {
    setLiked(!liked)
    likeMutation.mutate(id)
    handleOptimisticLike(id)
  }

  return (
    <Box
      padding={{ xs: '25px', sm: '38px 25px' }}
      display={{ xs: 'block', sm: 'flex' }}
      alignItems="center"
      justifyContent="space-between"
      bgcolor="rgba(82, 120, 255, 0.08)"
    >
      <Box
        alignItems="center"
        justifyContent="flex-start"
        display="flex"
        sx={{
          '& .MuiAvatar-root': {
            padding: '10px',
            background: '#2448ff'
          }
        }}
      >
        <Avatar
          alt="icon"
          src={iconLogoArabic}
          sx={{
            '& .MuiAvatar-img': {
              objectFit: 'contain'
            }
          }}
        />

        {parseInt(categoryId) === 5 ? (
          <CodeEditor
            value={code}
            language="js"
            placeholder="Please enter JS code."
            onChange={(evn) => setCode(evn.target.value)}
            padding={15}
            style={{
              fontSize: 12,
              backgroundColor: '#f5f5f5',
              fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace'
            }}
          />
        ) : (
          <Typography
            variant="h5"
            marginLeft="10px"
            fontWeight="400"
            fontSize="16px"
            color="#343434"
            dangerouslySetInnerHTML={{ __html: title }}
            style={{ whiteSpace: 'pre-wrap' }}
          ></Typography>
        )}
      </Box>
      <Box textAlign="right">
        <ButtonGroup
          sx={{
            border: '1px solid #C1C1C1',
            background: '#EFF2FC',
            borderRadius: '9px',
            padding: '7px',
            margin: { xs: '15px 0 0 0', sm: '0 40px' },
            '& .MuiIconButton-root:first-of-type': {
              borderRight: '1px solid #D5D5D5'
            },
            '& button': {
              borderRadius: '0',
              paddingTop: '0',
              paddingBottom: '0',
              '&:hover': { background: 'transparent' },
              '& img': {
                width: '20px',
                height: 'auto'
              }
            }
          }}
        >
          <IconButton onClick={() => handleCopy(title)}>
            <img src={copy ? iconCheck : iconCopy} alt="copy" />
          </IconButton>
          <IconButton onClick={onLike}>
            <img src={liked ? iconThumbsUpFilled : iconThumbsUp} alt="thumbs-up" />
          </IconButton>
        </ButtonGroup>
      </Box>
    </Box>
  )
}

export default ChatAnswer
