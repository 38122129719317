import { Button, CircularProgress } from '@mui/material'
import React from 'react'
import { FormattedMessage } from 'react-intl'

const RoundedButton = ({ small, title, type, onClick, loading, ...rest }) => {
  return (
    <Button
      variant="contained"
      sx={{
        background: 'linear-gradient(180deg, #5E82FF 0%, #0B41FF 100%)',
        color: '#fff',
        backdropFilter: ' blur(9px)',
        fontSize: { xs: small ? '14px' : '16px', sm: small ? '16px' : '18px' },
        borderRadius: '43px',
        textTransform: 'capitalize',
        boxShadow: 'none',
        padding: { xs: small ? '8px 20px !important' : '8px 20px', sm: small ? '6px 30px !important' : '9px 30px' },
        fontWeight: '500'
      }}
      type={type}
      onClick={onClick}
      disabled={loading}
      {...rest}
    >
      {loading ? <CircularProgress size={24} color="primary" /> : <FormattedMessage id={title} />}
    </Button>
  )
}

export default RoundedButton
