import React from 'react'

const IconEmail = ({ color }) => {
  return (
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.6456 26.1146H6.20484C5.74478 26.1146 5.37183 26.4876 5.37183 26.9477C5.37183 27.4077 5.74478 27.7807 6.20484 27.7807H15.6456C16.1057 27.7807 16.4786 27.4077 16.4786 26.9477C16.4786 26.4876 16.1057 26.1146 15.6456 26.1146Z"
        fill={color}
      />
      <path
        d="M10.0922 22.2272H6.20484C5.74478 22.2272 5.37183 22.6002 5.37183 23.0603C5.37183 23.5203 5.74478 23.8933 6.20484 23.8933H10.0922C10.5523 23.8933 10.9252 23.5203 10.9252 23.0603C10.9252 22.6002 10.5523 22.2272 10.0922 22.2272Z"
        fill={color}
      />
      <path
        d="M24.4757 32.2234H4.03911C3.52132 32.2234 3.0086 32.1214 2.53022 31.9233C2.05185 31.7251 1.61718 31.4347 1.25105 31.0685C0.884912 30.7024 0.594479 30.2677 0.396328 29.7894C0.198178 29.311 0.0961914 28.7983 0.0961914 28.2805V7.84392C0.0961914 6.79819 0.511605 5.79529 1.25105 5.05585C1.99049 4.31641 2.99339 3.90099 4.03911 3.90099H15.6457C15.8667 3.90099 16.0786 3.98876 16.2348 4.14498C16.391 4.3012 16.4788 4.51308 16.4788 4.73401C16.4788 4.95493 16.391 5.16681 16.2348 5.32303C16.0786 5.47925 15.8667 5.56702 15.6457 5.56702H4.03911C3.44997 5.56702 2.88496 5.80105 2.46837 6.21764C2.05178 6.63423 1.81775 7.19924 1.81775 7.78838V28.2249C1.81775 28.8141 2.05178 29.3791 2.46837 29.7957C2.88496 30.2123 3.44997 30.4463 4.03911 30.4463H24.4757C25.0648 30.4463 25.6298 30.2123 26.0464 29.7957C26.463 29.3791 26.697 28.8141 26.697 28.2249V16.6738C26.697 16.4529 26.7848 16.241 26.941 16.0848C27.0972 15.9286 27.3091 15.8408 27.53 15.8408C27.751 15.8408 27.9629 15.9286 28.1191 16.0848C28.2753 16.241 28.3631 16.4529 28.3631 16.6738V28.2805C28.3632 29.3166 27.9554 30.3112 27.2279 31.0491C26.5004 31.787 25.5117 32.2088 24.4757 32.2234Z"
        fill={color}
      />
      <g clipPath="url(#clip0_2_61)">
        <path
          d="M15.9749 16.0747L20.633 14.9268L29.2172 6.34259L25.707 2.83241L17.1231 11.4166L15.9749 16.0747Z"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.123 11.4166L20.6329 14.9268M29.2831 1.21252L30.837 2.76641C30.9655 2.89485 31.0674 3.04734 31.1369 3.21517C31.2064 3.383 31.2422 3.56289 31.2422 3.74455C31.2422 3.92622 31.2064 4.10611 31.1369 4.27394C31.0674 4.44177 30.9655 4.59426 30.837 4.7227L29.2171 6.34259L25.7069 2.83241L27.3271 1.21252C27.5865 0.953149 27.9383 0.807438 28.3051 0.807438C28.6719 0.807438 29.0237 0.953149 29.2831 1.21252Z"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2_61">
          <rect width="16.6602" height="16.6602" fill={color} transform="translate(15.334 0.111021)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default IconEmail
