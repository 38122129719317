import { Box, CircularProgress } from '@mui/material'
import React from 'react'

const Loading = ({ alignItems }) => {
  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        minHeight: '100vh',
        width: '100%',
        alignItems: alignItems || 'center',
        justifyContent: 'center',
        '& span': { color: '#1146FF' }
      }}
    >
      <CircularProgress />
    </Box>
  )
}

export default Loading
