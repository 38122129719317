import React, { useState } from 'react'
import { AppBar, Box, Button, Container, IconButton, Menu, MenuItem, Toolbar } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { iconLogoArabic } from '../../assets/images'
import { FormattedMessage } from 'react-intl'
import { Link, NavLink } from 'react-router-dom'

import { allRoutes } from 'shared/constants/allRoutes'
import { Language } from 'components'
import { useAuth } from 'routes/useAuth'

const Header = ({ isLogo }) => {
  const [anchorElNav, setAnchorElNav] = useState(null)
  const [isAuthenticated] = useAuth()

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }
  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: 'transparent',
        boxShadow: 'none',
        padding: { xs: '30px 0 10px', md: '50px 0 20px' },
        '& .MuiSelect-select': {
          color: 'white !important',
          lineHeight: '26px'
        }
      }}
    >
      <Container maxWidth="lg">
        <Toolbar disableGutters>
          <Box
            variant="div"
            as={NavLink}
            to="/"
            sx={{
              display: 'flex',
              flexGrow: 1,
              '& img': {
                width: { xs: '60px', md: '90px' },
                height: 'auto'
              }
            }}
          >
            {isLogo && <img src={iconLogoArabic} alt="logo" />}
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              justifyContent: 'flex-end',
              gap: { xs: '20px', md: '35px' },
              display: {
                xs: 'none',
                sm: 'flex',
                '& a': {
                  color: 'white',
                  display: 'block',
                  textTransform: 'capitalize',
                  fontWeight: '400',
                  fontSize: '20px',
                  padding: '0'
                }
              }
            }}
          >
            <Language />
            <Button onClick={handleCloseNavMenu} as={Link} to={allRoutes.about}>
              <FormattedMessage id="about" />
            </Button>
            <Button onClick={handleCloseNavMenu} as={Link} to={allRoutes.faq}>
              <FormattedMessage id="faq" />
            </Button>
            <Button onClick={handleCloseNavMenu} as={Link} to={allRoutes.contact}>
              <FormattedMessage id="contact" />
            </Button>
            {isAuthenticated && (
              <Button onClick={handleCloseNavMenu} as={Link} to={allRoutes.profile}>
                <FormattedMessage id="profile" />
              </Button>
            )}
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              alignItems: 'center',
              gap: '20px',
              justifyContent: 'flex-end',
              display: { xs: 'flex', sm: 'none' }
            }}
          >
            <Box>
              <Language />
            </Box>
            <IconButton
              sx={{ background: '#1146FF', color: '#fff', '&:hover': { background: '#1146FF', color: '#fff' } }}
              size="large"
              onClick={handleOpenNavMenu}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: {
                  xs: 'block',
                  md: 'none',
                  '& .MuiMenuItem-root': {
                    textTransform: 'capitalize',
                    fontWeight: '400',
                    fontSize: '16px',
                    textAlign: 'center',
                    color: '#343434'
                  }
                }
              }}
            >
              <MenuItem onClick={handleCloseNavMenu} as={Link} to={allRoutes.about}>
                <FormattedMessage id="about" />
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu} as={Link} to={allRoutes.faq}>
                <FormattedMessage id="faq" />
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu} as={Link} to={allRoutes.home}>
                <FormattedMessage id="contact" />
              </MenuItem>
              {isAuthenticated && (
                <MenuItem onClick={handleCloseNavMenu} as={Link} to={allRoutes.profile}>
                  <FormattedMessage id="profile" />
                </MenuItem>
              )}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}

export default Header
