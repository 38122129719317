import React, { useContext, useState } from 'react'
import { Box, FormControl, MenuItem, Select } from '@mui/material'
import { FormattedMessage } from 'react-intl'

import { LanguageContext } from '../../context/languageContext'
import { iconGlobe } from '../../assets/images'

const Language = () => {
  const { locale, selectLanguage } = useContext(LanguageContext)
  const [open, setOpen] = useState(false)
  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }
  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{
        '& img': { width: { xs: '20px', sm: '26px' }, cursor: 'pointer' },
        '& .MuiInputBase-root': {
          '& .MuiSelect-select': {
            padding: '5px 0 0 4px !important',
            fontWeight: '500',
            color: '#343434',
            fontSize: { xs: '16px', sm: '18px' }
          },
          '& .MuiSvgIcon-root': { display: 'none' },
          '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
        },
        '& .Mui-selected': {
          background: 'red'
        },
        "&[dir|='rtl']": {
          '& .MuiSelect-select': {
            padding: '5px 6px 0 0px !important'
          }
        }
      }}
      dir={locale === 'ar' ? 'rtl' : 'ltr'}
    >
      <img src={iconGlobe} alt="globe" onClick={handleOpen} />
      <FormControl sx={{ minWidth: 20 }}>
        <Select
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={locale}
          onChange={selectLanguage}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          defaultValue={locale}
        >
          <MenuItem value={'en'}>
            <FormattedMessage id="en" />
          </MenuItem>
          <MenuItem value={'ar'}>
            <FormattedMessage id="ar" />
          </MenuItem>
        </Select>
      </FormControl>
    </Box>
  )
}

export default Language
