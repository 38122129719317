import React from 'react'

const IconCode = ({ color }) => {
  return (
    <svg width="40" height="32" viewBox="0 0 40 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.4413 7.14347L2.41757 16.1672L11.4413 25.191C11.7918 25.5414 11.7918 26.0671 11.4413 26.4175C11.2661 26.5928 11.0471 26.6804 10.8281 26.6804C10.609 26.6804 10.39 26.5928 10.2148 26.4175L0.57777 16.7805C0.227332 16.4301 0.227332 15.9044 0.57777 15.554L10.2148 5.91694C10.5652 5.5665 11.0909 5.5665 11.4413 5.91694C11.7918 6.26738 11.7918 6.79303 11.4413 7.14347ZM39.4763 15.554L29.8393 5.91694C29.4889 5.5665 28.9632 5.5665 28.6128 5.91694C28.2623 6.26738 28.2623 6.79303 28.6128 7.14347L37.6365 16.1672L28.6128 25.191C28.2623 25.5414 28.2623 26.0671 28.6128 26.4175C28.788 26.5928 29.007 26.6804 29.226 26.6804C29.4451 26.6804 29.6641 26.5928 29.8393 26.4175L39.4763 16.7805C39.8268 16.4301 39.8268 15.9044 39.4763 15.554ZM25.1084 0.441354C24.6703 0.30994 24.1447 0.528963 24.0133 1.01081L14.3762 30.798C14.2448 31.2799 14.4639 31.7617 14.9457 31.8931C15.0333 31.9369 15.1209 31.9369 15.2085 31.9369C15.559 31.9369 15.9094 31.7179 16.0408 31.3237L25.6779 1.53647C25.8093 1.09842 25.5902 0.572768 25.1084 0.441354Z"
        fill={color}
      />
    </svg>
  )
}

export default IconCode
