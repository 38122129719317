import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

export const validationErrors = {
  required: <FormattedMessage id="required" />,
  email: <FormattedMessage id="validEmail" />,
  pleaseEnterMessage: <FormattedMessage id="pleaseEnterMessage" />,
  minLength: (length) => `${formatMessage({ id: 'enterGreater' })} ${length}.`,
  validPhone: <FormattedMessage id="validPhone" />,
  rangeLength: (min, max) =>
    `${formatMessage({ id: 'rangeLength' })} ${min} ${formatMessage({ id: 'and' })} ${max} ${formatMessage({
      id: 'charLong'
    })}`,
  maxLength: (length) => `${formatMessage({ id: 'enterLess' })} ${length}.`,
  commonRange: (min = 5, max = 1000) =>
    `${formatMessage({ id: 'rangeLength' })} ${min} ${formatMessage({ id: 'and' })} ${max} ${formatMessage({
      id: 'charLong'
    })}`
}

const formatMessage = (messageDescriptor) => {
  const intl = useIntl()
  return intl.formatMessage(messageDescriptor)
}
