import React from 'react'
import { Box, Typography } from '@mui/material'
import Avatar from 'react-avatar'

const ChatQuestion = ({ title }) => {
  const user = JSON.parse(localStorage.getItem('user'))

  return (
    <Box padding={{ xs: '30px 25px', sm: '30px' }} display="flex" alignItems="center">
      <Box marginLeft={{ xs: '0', sm: '-5px' }}>
        <Avatar name={user?.name} size="40px" round />
      </Box>
      <Typography variant="h5" marginLeft="10px" fontWeight="400" fontSize="16px" color="#343434" whiteSpace="pre-wrap">
        {title}
      </Typography>
    </Box>
  )
}

export default ChatQuestion
