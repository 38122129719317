import React, { useEffect, useRef, useState, useContext } from 'react'
import { Box, FormGroup, FormHelperText } from '@mui/material'
import { useIntl } from 'react-intl'
import { useMutation } from '@tanstack/react-query'
import CommonButton from 'components/button'
import axiosInstance from 'shared/libs/axios'
import { apiPaths } from 'shared/constants/apiPaths'
import { LanguageContext } from 'context/languageContext'

let currentOtpIndex = 0
const Otp = ({ onNext, formData, setFormData, otpType, isSubscribed }) => {
  const { locale } = useContext(LanguageContext)
  const [otp, setOtp] = useState(new Array(4).fill(''))
  const [otpError, setOtpError] = useState(false)
  const [activeOtpIndex, setActiveOtpIndex] = useState(0)
  const otpRef = useRef()
  // const currentOtpIndex = useRef();

  const verifyOtp = async (data) => {
    return await axiosInstance.post(apiPaths.verifyOtp, data)
  }

  const { mutate, isLoading } = useMutation((data) => verifyOtp(data))

  useEffect(() => {
    otpRef.current?.focus()
  }, [activeOtpIndex])

  useEffect(() => {
    if (!otp.includes('')) {
      setOtpError(false)
    }
  }, [otp])

  const handleChange = (e) => {
    const value = e.target.value
    const newOtp = [...otp]
    newOtp[currentOtpIndex] = value.substring(value.length - 1)
    if (!value) {
      setActiveOtpIndex(currentOtpIndex - 1)
    } else {
      setActiveOtpIndex(currentOtpIndex + 1)
    }
    setOtp(newOtp)
  }

  const handleOnKeyDown = ({ key }, index) => {
    currentOtpIndex = index
    if (key === 'Backspace') {
      setActiveOtpIndex(currentOtpIndex - 1)
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (otp.includes('')) {
      setOtpError(true)
    } else {
      setOtpError(false)
      setFormData({ ...formData, mobile_otp: otp.join('') })
      mutate(
        { mobile: formData.mobile, mobile_otp: otp.join('') },
        {
          onSuccess: (data) => {
            localStorage.setItem('userToken', data?.data?.token)
            onNext()
          }
        }
      )
    }
  }

  const handleSubscribe = async (e) => {
    e.preventDefault()
    if (isSubscribed) {
      await axiosInstance
        .post(apiPaths.requestUnSubscribeVerify, { otp: otp.join('') })
        .then((res) => {
          window.location.reload(true)
        })
        .catch((err) => {
          setOtpError(err)
        })
    } else {
      await axiosInstance
        .post(apiPaths.requestSubscribeVerify, { otp: otp.join('') })
        .then((res) => {
          window.location.reload(true)
        })
        .catch((err) => {
          setOtpError(err)
        })
    }
  }

  return (
    <form onSubmit={otpType === 'subscribe' ? handleSubscribe : handleSubmit} dir="ltr">
      <Box
        sx={{
          marginBottom: '15px',
          '& .form-group': {
            marginBottom: '0',
            '& input': {
              border: 'none',
              outline: 'none',
              width: '100%',
              borderRadius: '10px',
              textAlign: 'center',
              fontWeight: '600'
            }
          }
        }}
      >
        <Box display="flex" gap="10px">
          {otp.map((_, index) => {
            return (
              <FormGroup className="form-group" key={index}>
                <input
                  ref={index === activeOtpIndex ? otpRef : null}
                  type="number"
                  className="otp"
                  onChange={handleChange}
                  onKeyDown={(e) => handleOnKeyDown(e, index)}
                  value={otp[index]}
                />
              </FormGroup>
            )
          })}
        </Box>
        {otpError && (
          <FormHelperText style={{ textAlign: locale === 'ar' ? 'right' : 'left' }} error>
            {useIntl().formatMessage({ id: 'pleaseEnterValidOtp' })}
          </FormHelperText>
        )}
      </Box>
      <CommonButton title="confirm" type="submit" loading={isLoading} />
    </form>
  )
}

export default Otp
