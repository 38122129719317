import { Box, FormControl, IconButton, MenuItem, Select, TextareaAutosize } from '@mui/material'
import React, { useContext, useEffect, useState, useRef } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { toast } from 'react-hot-toast'
import iso6391 from 'iso-639-1'
import { NestedMenuItem } from 'mui-nested-menu'

import { iconMessage, iconAudio, redAudio } from 'assets/images'
import { LanguageContext } from 'context/languageContext'
import { validationErrors } from 'shared/constants/ValidationErrors'

const ChatInput = ({ handleSubmit: submitHandler, languageDropDown, onAudioSubmit }) => {
  const { locale } = useContext(LanguageContext)
  const [open, setOpen] = useState(false)
  const languages = iso6391.getAllNames()
  const [selectedLanguage, setSelectedLanguage] = useState('English')
  const mediaRecorderRef = useRef(null)
  const audioChunksRef = useRef([])
  const mediaStreamRef = useRef(null) // Add this line to define mediaStreamRef
  const [isRecording, setIsRecording] = useState(false) // State to track if currently recording
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm({
    defaultValues: {
      question: '',
      language: 'English'
    }
  })

  const onSubmit = (data) => {
    const formData = { ...data, language: selectedLanguage }
    submitHandler(formData)
    setValue('question', '')
  }

  useEffect(() => {
    if (errors?.question?.message) {
      toast.error(errors?.question?.message)
    }
  }, [errors])

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }
  const handleAudioClick = async () => {
    if (isRecording) {
      // Stop recording if already recording
      mediaRecorderRef.current.stop()
      console.log('Recording stopped')
      setIsRecording(false)

      // Stop all tracks to release the microphone
      if (mediaStreamRef.current) {
        mediaStreamRef.current.getTracks().forEach((track) => track.stop())
        console.log('Microphone stream stopped')
      }
    } else {
      try {
        // Request microphone permissions
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true })

        // Store the stream reference to stop it later
        mediaStreamRef.current = stream

        // Create a MediaRecorder instance
        mediaRecorderRef.current = new MediaRecorder(stream)
        audioChunksRef.current = [] // Clear previous audio chunks

        mediaRecorderRef.current.ondataavailable = (event) => {
          audioChunksRef.current.push(event.data)
        }

        // Start recording
        mediaRecorderRef.current.start()
        console.log('Recording started')
        setIsRecording(true)

        mediaRecorderRef.current.onstop = () => {
          // Create a Blob from the audio chunks and do something with it
          const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' })
          audioChunksRef.current = [] // Clear chunks for next recording

          // Process the recorded audio
          const formData = { file: audioBlob, language: selectedLanguage }
          onAudioSubmit(formData)
          setValue('question', '')
        }
      } catch (error) {
        console.error('Error accessing microphone:', error)
      }
    }
  }
  return (
    <>
      <Box
        className="message-input"
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        position={{ xs: 'relative', sm: 'inherit' }}
        marginTop={{ xs: '80px', md: 0 }}
        dir={locale === 'ar' ? 'rtl' : 'ltr'}
        sx={{
          "&[dir|='rtl']": {
            '& button': {
              marginRight: { xs: '10px', sm: '15px' },
              marginLeft: '0',
              '& img': {
                transform: 'rotate(250deg)'
              }
            }
          },
          '& button': {
            background: 'linear-gradient(180deg, #5E82FF 0%, #0B41FF 100%)',
            borderRadius: { xs: '8px', sm: '12px' },
            marginLeft: { xs: '10px', sm: '15px' },
            width: { xs: '40px', sm: '73px' },
            height: { xs: '40px', sm: '63px' },
            '& img': {
              width: { xs: '20px', sm: '40px' },
              height: 'auto'
            }
          },
          '& textarea': {
            width: '100%',
            border: 'none',
            outline: 'none',
            resize: 'none',
            color: '#343434',
            fontSize: { xs: '18px', sm: '21px' },
            fontFamily: 'var(--font_i)',
            fontWeight: 400,
            borderRadius: '12px',
            background: '#F3F3F3',
            padding: { xs: '10px', sm: '20px' },
            '&::placeholder': {
              color: '#343434',
              opacity: 1
            },
            '&:: -webkit-scrollbar': {
              width: '8px'
            },
            '&:: -webkit-scrollbar-thumb': {
              background: '#0B41FF',
              borderRadius: '10px'
            }
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none'
          }
        }}
      >
        <Box
          position={{ xs: 'fixed', sm: 'absolute' }}
          bottom={{ xs: '20px', sm: '40px', xl: '50px' }}
          width={{ xs: '95%', lg: '1022px' }}
          marginX={{ xs: 'auto', md: '0' }}
          left={{ xs: '0', md: '50%' }}
          right={{ xs: '0', sm: 'inherit' }}
          bgcolor="#fff"
          boxShadow="0px 7px 12px 1px rgba(0, 0, 0, 0.06)"
          borderRadius={{ xs: '10px', sm: '22px' }}
          padding={{ xs: '10px', sm: '20px' }}
          sx={{ transform: { md: 'translateX(-50%)' } }}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Controller
            name="question"
            control={control}
            rules={{
              required: {
                value: true,
                message: validationErrors.pleaseEnterMessage
              }
            }}
            render={({ field: { onChange, value } }) => (
              <TextareaAutosize
                maxRows={2}
                fullWidth
                hiddenLabel
                placeholder={useIntl().formatMessage({ id: 'sendMessage' })}
                value={value}
                onChange={(e) => onChange(e)}
              />
            )}
          />

          {languageDropDown && (
            <Controller
              name="language"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: validationErrors.required
                }
              }}
              render={({ field: { onChange, value } }) => (
                <FormControl sx={{ width: '150px' }}>
                  <Select
                    open={open}
                    onClose={handleClose}
                    onOpen={handleOpen}
                    value={selectedLanguage}
                    onChange={(e) => {
                      setSelectedLanguage(e.target.value)
                    }}
                    inputProps={{ 'aria-label': 'Without label' }}
                    defaultValue="Arabic"
                    MenuProps={{
                      style: { maxHeight: '250px' }
                    }}
                  >
                    {languages?.map(
                      (language, index) =>
                        ['English', 'Arabic', 'French'].includes(language) && (
                          <MenuItem key={index} value={language}>
                            {language}
                          </MenuItem>
                        )
                    )}
                    {languages?.map(
                      (language, index) =>
                        !['English', 'Arabic', 'French'].includes(language) && (
                          <MenuItem
                            key={language}
                            value={language}
                            sx={{
                              display: 'none'
                            }}
                          >
                            {language}
                          </MenuItem>
                        )
                    )}
                    <NestedMenuItem
                      label={useIntl().formatMessage({ id: 'otherLanguages' })}
                      parentMenuOpen={open}
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        e.nativeEvent.stopImmediatePropagation()
                      }}
                    >
                      {languages?.map(
                        (language, index) =>
                          !['English', 'Arabic', 'French'].includes(language) && (
                            <MenuItem
                              key={language}
                              value={language}
                              data-value={language}
                              onClick={(e) => {
                                setSelectedLanguage(e.target?.dataset?.value)
                                handleClose()
                              }}
                            >
                              {language}
                            </MenuItem>
                          )
                      )}
                    </NestedMenuItem>
                  </Select>
                </FormControl>
              )}
            />
          )}

          <IconButton aria-label="audio" size="large" onClick={handleAudioClick}>
            {isRecording ? (
              <img src={redAudio} alt="redAudio" style={{ transform: 'rotate(360deg)' }} />
            ) : (
              <img src={iconAudio} style={{ transform: 'rotate(360deg)' }} alt="audio" />
            )}
          </IconButton>

          <IconButton aria-label="delete" size="large" type="submit">
            <img src={iconMessage} alt="message" />
          </IconButton>
        </Box>
      </Box>
    </>
  )
}

export default ChatInput
