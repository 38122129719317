import { ThemeProvider } from '@emotion/react'
import { CssBaseline, createTheme } from '@mui/material'
import React, { Suspense, useMemo, useState } from 'react'
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Toaster, toast } from 'react-hot-toast'
import { createBrowserHistory } from 'history'
import { BrowserRouter as Router } from 'react-router-dom'

import { themeSettings } from './theme'
import { GlobalTheme } from './theme/GlobalTheme'
import { Loading } from 'components'
import { LanguageContextWrapper } from 'context/languageContext'

export const history = createBrowserHistory()

const Routes = React.lazy(() => import('routes'))

const App = () => {
  const queryClientWithDefaultOptions = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false
      }
    },
    queryCache: new QueryCache({
      onError: (error) => {
        toast.error(`Something went wrong: ${error?.response?.data?.errors?.[0]?.msg}`)
      }
    }),
    mutationCache: new MutationCache({
      onError: (error) => {
        toast.error(`Something went wrong: ${error?.response?.data?.errors?.[0]?.msg}`)
      }
    })
  })

  const theme = useMemo(() => createTheme(themeSettings), [])
  const [queryClient] = useState(() => queryClientWithDefaultOptions)

  return (
    <QueryClientProvider client={queryClient}>
      <LanguageContextWrapper>
        <Toaster />
        <ThemeProvider theme={theme}>
          <GlobalTheme theme={theme} />
          <CssBaseline />
          <Router history={history}>
            <Suspense fallback={<Loading />}>
              <Routes />
            </Suspense>
          </Router>
        </ThemeProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </LanguageContextWrapper>
    </QueryClientProvider>
  )
}

export default App
