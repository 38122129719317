import axios from 'axios'

const baseURL = 'https://api.liby.ai/v1/'
// const baseURL = 'http://127.0.0.1:8080/v1/'

const axiosInstance = axios.create({
  baseURL
})

axiosInstance.interceptors.request.use(
  (req) => {
    // console.log(req)
    const token = localStorage.getItem('userToken')
    if (!req.url.includes('https') && token) {
      req.headers.Authorization = `Bearer ${token}`
      return req
    }
    return req
  },
  (err) => {
    return Promise.reject(err)
  }
)

axiosInstance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response && (error.response.status === 417 || error.response.status === 401)) {
      localStorage.clear()
    }
    return Promise.reject(error)
  }
)

export default axiosInstance
