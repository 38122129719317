import React from 'react'
import { Box, Modal, Typography } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'

import { iconLogoBlueArabic } from 'assets/images'
import { RoundedButton } from 'components'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  maxWidth: '95%',
  background: '#fff',
  outline: 'none',
  p: { xs: 2, sm: 4 },
  textAlign: 'center'
}

const CancelSubscriptionModal = ({ open, handleClose }) => {
  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="profile-modal-title" aria-describedby="profile-modal-description">
      <Box sx={style}>
        <img src={iconLogoBlueArabic} alt="" />
        <Typography fontSize="16px" color="#000000" fontWeight="400" marginTop="10px">
          <FormattedMessage id="areYouSureYouWantCancelSubscription" />
        </Typography>
        <Box display="flex" justifyContent="center" gap={{ xs: '10px', sm: '40px' }} paddingX={{ xs: '20', sm: '40px' }} marginTop="20px">
          <RoundedButton title={useIntl().formatMessage({ id: 'yes' })} fullWidth onClick={handleClose} />
          <RoundedButton title={useIntl().formatMessage({ id: 'no' })} fullWidth onClick={handleClose} />
        </Box>
      </Box>
    </Modal>
  )
}

export default CancelSubscriptionModal
