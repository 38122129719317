import React from 'react'
import { toast } from 'react-hot-toast'
import { FormattedMessage } from 'react-intl'

export const copyToClipboard = (text) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      toast.success(<FormattedMessage id="copiedToClipboard" />)
    })
    .catch(() => {
      toast.error(<FormattedMessage id="errorCopyToClipboard" />)
    })
}

export const setCurrentUser = (user) => {
  localStorage.setItem('user', JSON.stringify(user))
}

export const getCurrentUser = () => {
  const user = localStorage.getItem('user')
  return JSON.parse(user)
}
