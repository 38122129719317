import React, { useContext } from 'react'
import { Box, Modal, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { Otp } from 'components'
import { LanguageContext } from 'context/languageContext'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  maxWidth: '95%',
  background: '#fff',
  outline: 'none',
  p: { xs: 2, sm: 4 },
  textAlign: 'center'
}

const SubscribeModal = ({ open, handleClose, ...props }) => {
  const { locale } = useContext(LanguageContext)
  // const [otpModal, setOtpModal] = useState(true)

  // const handleOtpModal = () => {
  //   setOtpModal(!otpModal)
  // }
  const handleOtpModalClose = () => {
    // setOtpModal(false)
    handleClose()
  }

  return (
    <Modal open={open} onClose={handleOtpModalClose} aria-labelledby="profile-modal-title" aria-describedby="profile-modal-description">
      <Box sx={style}>
        {/* {!otpModal && (
          <>
            <img src={iconLogoBlueArabic} alt="" />
            <Typography fontSize="16px" color="#000000" fontWeight="400" marginTop="10px">
              <FormattedMessage id="areYouSureYouWantAddMoreWord" />
            </Typography>
            <Box
              display="flex"
              justifyContent="center"
              gap={{ xs: '10px', sm: '40px' }}
              paddingX={{ xs: '20', sm: '40px' }}
              marginTop="20px"
            >
              <RoundedButton title={useIntl().formatMessage({ id: 'yes' })} fullWidth onClick={handleOtpModal} />
              <RoundedButton title={useIntl().formatMessage({ id: 'no' })} fullWidth onClick={handleOtpModalClose} />
            </Box>
          </>
        )} */}
        <Box textAlign={locale === 'ar' ? 'right' : 'left'}>
          <Typography variant="h1" mb="20px" color="#263241" fontWeight="700" fontSize={{ xs: '20px', sm: '22px' }}>
            <FormattedMessage id="enterOtp" />
          </Typography>
          <Otp {...props} />
        </Box>
      </Box>
    </Modal>
  )
}

export default SubscribeModal
